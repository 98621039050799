* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  /* background-color: #eaeaea; */
  background-color: #fff;
}

@font-face {
  font-family: 'Avenir_Black';
  src: local("Avenir_Black"), url('./fonts/Avenir\ Black.ttf')  format("truetype");
}

@font-face {
  font-family: 'Avenir_Book';
  src: local("Avenir_Book"), url('./fonts/Avenir\ Book.ttf')  format("truetype");
}

@font-face {
  font-family: 'Avenir_Heavy';
  src: local("Avenir_Heavy"), url('./fonts/Avenir\ Heavy.ttf')  format("truetype");
}

@font-face {
  font-family: 'Avenir_Regular';
  src: local("Avenir_Regular"), url('./fonts/Avenir\ Regular.ttf')  format("truetype");
}

@font-face {
  font-family: 'Avenir_Light';
  src: local("Avenir_Light"), url('./fonts/Avenir\ Light.ttf')  format("truetype");
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: #eaeaea;
  font-family: "Roboto", "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: aqua; */
}

h1 {
  font-family: Righteous;
}

h2 {
  font-family: Righteous;
}

p, h3, h4, h5, h6 {
  font-family: sans-serif;
}

.App{
  font-size: 1.6rem;
}

.container {
  padding: 0 3rem;
}

p {
  font-size: 1.6rem;
}

/* 
@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}
 */

@media (min-width: 1200px) {
/*   
  .container {
    max-width: 1170px;
  }
 */
  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.1rem;
  }

  p {
    font-size: 2rem;
  }
}

@media (min-width: 1600px) {

/*   
  .container {
    max-width: 1530px;
  }
 */
  h1 {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 2.1rem;
  }

  h6 {
    font-size: 1.5rem;
  }
}

.leaflet-container{
  width: 100%;
  height: 25vh;
  position: relative;
  z-index: 1;
  border-radius: 28px;
}